<template>
	<div class="notification" role="notification" :class="notificationClassArray" data-test="notification">
		<svg-icon :icon="iconName" class="notification-icon" />

		<div class="notification-body">
			<div class="top-right-actions">
				<svg-icon
					v-if="isMostRecentNotification && pastNotificationStackThreshold"
					icon="caret-down"
					@click.native="$emit('toggle-collapse')"
				/>
				<svg-icon
					v-if="isMostRecentNotification && pastNotificationStackThreshold"
					icon="close-box-multiple-outline"
					data-test="notification-dismiss-all"
					@click.native="dismissAll"
				/>
				<svg-icon v-else icon="close" data-test="notification-dismiss" @click.native="$emit('dismiss', $vnode.key)" />
			</div>
			<!-- Message -->
			<span v-html="message"></span>
		</div>
	</div>
</template>

<script>
import { openReportIssue } from '@dialogs/ReportIssue.vue'
import { eventBus } from '@services/eventBus'

export default {
	name: 'VNotificationsNotification',
	props: {
		index: {
			type: Number,
			required: true,
		},
		notificationType: {
			type: String,
			default: 'info',
		},
		message: {
			type: String,
			required: true,
		},
		duration: {
			type: Number,
			default: 15000,
		},
		closeOnLogout: {
			type: Boolean,
			default: false,
		},
		isMostRecentNotification: {
			type: Boolean,
			required: true,
		},
		pastNotificationStackThreshold: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			timeoutHandler: null,
		}
	},
	computed: {
		notificationClassArray() {
			const classArray = [`notification-${this.notificationType}`, `notification-${this.index}`]
			return classArray
		},
		iconName() {
			switch (this.notificationType) {
				case 'info':
					return 'info-circle'
				case 'question':
					return 'question-circle'
				case 'success':
					return 'check-circle'
				case 'warn':
					return 'exclamation-triangle'
				case 'lock':
					return 'lock'
				default:
					// error
					return 'exclamation-circle'
			}
		},
	},
	mounted() {
		this.startTimeoutIfVisible()
		if (this.closeOnLogout) eventBus.on(eventBus.type.LOGOUT, this.dismiss)
	},
	destroyed() {
		// Clear timeout
		if (this.timeoutHandler !== null) {
			clearTimeout(this.timeoutHandler)
			this.timeoutHandler = null
		}
		if (this.closeOnLogout) eventBus.off(eventBus.type.LOGOUT, this.dismiss)
	},
	methods: {
		reportIssue() {
			const clinicCode = this.$store.getters.isAuthenticated
				? this.$store.state.auth.claims.activeClinicCode
				: this.$route.query.clinicCode
			openReportIssue({ clinicCode })
		},
		startTimeoutIfVisible() {
			if (!this.duration) return
			// If page is not visible, check again later
			if (document.hidden || document.msHidden || document.webkitHidden) {
				setTimeout(() => {
					this.startTimeoutIfVisible()
				}, 750)
				return
			}
			// Set a timer to remove this notification
			this.timeoutHandler = setTimeout(this.dismiss, this.duration)
		},
		dismiss() {
			this.$emit('dismiss', this.$vnode.key)
		},
		dismissAll() {
			this.$emit('dismiss-all', this.$vnode.key)
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

$notification-width: 320px;
$notification-height: 120px;
$notification-top-bottom-margin: 4px;
$notification-border-left-width: 36px;

// DEFAULT
.notification {
	position: absolute;
	width: $notification-width;
	min-height: $notification-height;
	background: var(--secondary-bg);
	border-radius: 6px;
	margin: $notification-top-bottom-margin 0;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
	font-weight: 400;
	transition: bottom 0.3s ease-in-out;
}

.notification:last-child {
	z-index: 900;
	transition: all 0.3s ease-in-out;
}

/* purgecss start ignore */
@for $i from 0 through 10 {
	.notification-#{$i} {
		bottom: ($notification-height + ($notification-top-bottom-margin * 2)) * $i;
	}
}
/* purgecss end ignore */

.notification-body {
	font-size: 15px;
	border-left: $notification-border-left-width solid #555;
	border-radius: 5px;
	line-height: 1.2;
	flex-grow: 1;
	padding: 8px;
	min-height: $notification-height;
	overflow: hidden;
	word-break: break-word;
}

.top-right-actions {
	float: right;
	margin-right: -8px;
	margin-top: -8px;
	.icon {
		cursor: pointer;
		padding: 8px;
		opacity: 0.75;
		&:hover {
			opacity: 1;
		}
	}
}

.add-info {
	font-size: 0.9em;
	padding-top: 8px;
}

.notification-icon {
	color: #fff;
	font-size: 20px;
	position: absolute;
	text-align: center;
	justify-content: center;
	top: 8px;
	width: 36px;
	filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
}

// COLLAPSED
.notifications-container.collapsed {
	.notification {
		bottom: 0;
		left: 0;
	}
	.notification:last-child {
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 6px 0 -1px var(--secondary-bg), 0 7px 6px rgba(0, 0, 0, 0.1),
			0 11px 0 -1px var(--secondary-bg), 0 12px 6px rgba(0, 0, 0, 0.1);
	}
}

/* Flavors */

/* Info */
.notification.notification-info {
	.notification-body {
		border-left-color: var(--notification-info-bg);
	}
	.notification-icon {
		color: var(--notification-info-icon);
	}
}

/* Question */
.notification.notification-question {
	.notification-body {
		border-left-color: var(--notification-question-bg);
	}
	.notification-icon {
		color: var(--notification-question-icon);
	}
}

/* Success */
.notification.notification-success {
	.notification-body {
		border-left-color: var(--notification-success-bg);
	}
	.notification-icon {
		color: var(--notification-success-icon);
	}
}

/* Warn / Lock */
.notification.notification-warn,
.notification.notification-lock {
	.notification-body {
		border-left-color: var(--notification-warning-bg);
	}
	.notification-icon {
		color: var(--notification-warning-icon);
	}
}

/* Error */
.notification.notification-error {
	.notification-body {
		border-left-color: var(--notification-danger-bg);
	}
	.notification-icon {
		color: var(--notification-danger-icon);
	}
}
</style>
