// Vue
import router from '@router'
import store from '@store'
import Vue from 'vue'
import App from './App.vue'
// Exception Tracking
// Plugins
import VueCompositionApi from '@vue/composition-api'
import VueGtag from 'vue-gtag'
import VueNotifications from './plugins/VueNotifications'
// Need to import CSS for Vue Select since v3.x
import SvgIcon from '@components/SvgIcon.vue'
import VSelect from '@components/VSelect.vue'
import 'vue-select/dist/vue-select.css'

// Mixins
import localization from './mixins/localization'
// Custom
import mediaMixin from '@/mixins/MediaMixin.js'
import api from '@services/api'
import { gaMap } from '@store/modules/analytics'
import './directives/focus'
import './directives/resizable'
import './directives/sortable'
import './filters'
import './registerServiceWorker'

import Cell from '@reporting/components/Cell.vue'
import Layout from '@reporting/components/Layout.vue'
import storage from '@services/storage'

// initalize global services
import '@/electron/omniDesktop'
import '@services/billingCodes'
import '@services/dicomSend'
import '@services/imagePrecache'
import '@services/menu'

// @ts-ignore
Vue.component('layout', Layout)
// @ts-ignore
Vue.component('cell', Cell)

// clean up old localforage indexedDB database to avoid QuotaExceededError
indexedDB.deleteDatabase('localforage') // TODO: we can remove this after a while

// Polyfills
require('canvas-toBlob') // Edge

// Directives
require('./directives/draggable.js')
require('./directives/dblclick.js')
require('./directives/scrollNav.js')

// Desktop Builds:
// `window.ElectronInterface` will come from Electron's preload script,
// and `vue-electron` will be loaded from that interface
const ElectronInterface = window.ElectronInterface

// Can be configured further:
// https://github.com/MatteoGabriele/vue-analytics

Vue.use(VueGtag, generateAnalyticsConfigObject())

Vue.use(VueCompositionApi)
Vue.use(VueNotifications, { store })

Vue.component('v-select', VSelect)
Vue.component('svg-icon', SvgIcon)

Vue.mixin(localization)
Vue.mixin(mediaMixin)

Vue.prototype.$api = api
Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV !== 'production'

Vue.prototype.$IDEXXAPI = 'IDEXXVMSAPI'
Vue.prototype.$VetCTAPI = 'VETCTAPIINTEGRATION'
Vue.prototype.$VetsChoice_ClinicCode = 'VTSCHOAPIINTEGRATION'
Vue.prototype.$TELERADIOLOGY_INTEGRATION_CLINIC_CODES = [
	Vue.prototype.$IDEXXAPI,
	Vue.prototype.$VetCTAPI,
	Vue.prototype.$VetsChoice_ClinicCode,
]

start()

function start() {
	/* eslint-disable no-new */
	const app = new Vue({
		store,
		router,
		render: createEle => createEle(App),
	}).$mount('#app')

	// If running inside Cypress
	if (window.Cypress) {
		// Attach the app to the window, which can be useful
		// for manually setting state in Cypress commands
		// such as `cy.logIn()`
		window.__app__ = app
	}
}

function generateAnalyticsConfigObject() {
	const isElectron = !!ElectronInterface
	const set = [{ field: gaMap.OMNI_VERSION, value: window.config.release }]
	const fields: any = {}
	const isProduction = process.env.NODE_ENV === 'production'
	if (isElectron) {
		set.push({ field: 'checkProtocolTask', value: null })
		fields.storage = 'none'
		const clientId = localStorage.getItem('ga:clientId')
		if (clientId) fields.clientId = clientId
	}
	return {
		config: { id: storage.getItem('ga:tokenId') || window.config.gaPropertyTrackingId || 'G-Y1X3JJKQG2' },
		fields: fields,
		set: set,
		autoTracking: {
			exception: true,
		},
		debug: {
			enabled: !isProduction && process.env.VUE_APP_GA_ENABLED,
			sendHitTask: isProduction,
		},
	}
}
