










































import { mapActions, mapState, mapGetters } from 'vuex'
import AstThumbnailList from '@components/view/ViewerThumbnailList.vue'
import AstAttachmentList from '@components/AttachmentList.vue'
import AstReportList from '@components/view/ViewerReportList.vue'
import { SIGNALPET_IMAGEVIEW_ID } from '@services/dicomPartners'
import consultations from "@services/consultationService";
import { studyData } from '@services/studyData'

export default {
	name: 'ViewerStudy',
	components: {
		AstThumbnailList,
		AstAttachmentList,
		AstReportList,
	},
	props: {
		study: {
			type: Object,
			required: true,
		},
		showHeading: {
			type: Boolean,
			default: false,
		},
		showPatientName: {
			type: Boolean,
			default: false,
		},
		clinicCode: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			isCollapsed: false,
			reports: [],
		}
	},
	computed: {
		hasSignalPETAttachment() {
			return this.study.imageData.attachments.find(a => a.imageViewId === SIGNALPET_IMAGEVIEW_ID)
		},
		...mapGetters(['isAuthenticated']),
		...mapState({
			// @ts-ignore
			consultationRequest: state => state.viewer.consultationRequest,
			// @ts-ignore
			viewer: state => state.viewer,
		}),
	},
	watch: {
		showHeading(show) {
			if (!show) this.isCollapsed = false
		},
	},
	async created() {
		await Promise.all([
			this.getReports(),])
	},
	methods: {
		...mapActions(['removeStudy', 'updateCanvas']),
		gotoSignalPET() {
			this.$router.push({
				name: 'signalpet',
				params: {
					studyId: this.study.studyId,
					clinicCode: this.study.clinicCode,
				},
			})
		},
		async getReports() {
			let reports = []
			const data = await studyData.getRelatedStudiesAndReports(this.study.studyId, this.clinicCode)

			if (!data) return
			if (this.consultationRequest.toEmail) {
				const emailedReports = this.consultationRequest.reports
				reports = data.reports.filter(r => emailedReports.some(emailedReport => (emailedReport.reportId === r.reportId) || (emailedReport.reportId === r.consultantReportId)))
			} else {
				reports = data.reports
			}
			for (const report of reports) {

				const { reportId, consultantReportId, isComplete } = report
				if (consultantReportId !== null && this.isAuthenticated) {
					let consultantReport = await consultations.getConsultantReport(consultantReportId)
					report.consultantClinicCode = consultantReport.consultant.clinicCode

					if (consultantReport.consultant.clinicCode === this.$VetCTAPI) {
						var vetCTCase = await this.getThirdPartyRadiologyReport(report)
						if (vetCTCase !== undefined && vetCTCase["Success"] === true && vetCTCase["Message"]) {
							report.isVetCT = true
							report.thirdPartyCaseLink = vetCTCase.Message
							report.vetCTCaseStatus = vetCTCase.Data
						}
					}
					else if (consultantReport.consultant.clinicCode === this.$VetsChoice_ClinicCode) {
						var thirdPartyReportResult = await this.getThirdPartyRadiologyReport(report)
						if (thirdPartyReportResult !== undefined && thirdPartyReportResult["Success"] === true && thirdPartyReportResult["Message"]) {
							report.thirdPartyCaseLink = thirdPartyReportResult.Message
						}
					}
				}
			}
			this.reports = reports
		},
		async getThirdPartyRadiologyReport(report: IReportInfo) {
			var result
			const { reportId, consultantReportId, isComplete } = report
			if (consultantReportId !== null) {
				let consultantReport = await consultations.getConsultantReport(consultantReportId)
				result = await consultations.getCase(consultantReport.consultant.clinicCode, consultantReportId)
				this.thirdPartyCaseLink = result.Message
				this.vetCTCaseStatus = result.Data
			}
			return result
		},
	},
}
