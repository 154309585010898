import router from '@router'
import api, { API } from '@services/api'
import storage from '@services/storage'
import jwtDecode from 'jwt-decode'

import { omniDesktop } from '@/electron/omniDesktop'
import { studyFolders } from '@/services/folders'
import checkSafeToLogout from '@/utils/checkSafeToLogout'
import { dlg } from '@/utils/dlgUtils'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import GoogleAnalytics from '@services/analyticsService'
import { clinicAPI } from '@services/clinicAPI'
import { dicomPartners } from '@services/dicomPartners'
import { eventBus } from '@services/eventBus'
import { lockService } from '@services/lockService'
import salesService from '@services/salesService'
import { uploadData } from '@services/uploads'
import workflow from '@services/workflow'
import wsData from '@services/wsData'
import * as RefreshToken from '@utils/refreshToken'
window.addEventListener('beforeunload', onAppClosing)

const TINYMCE_CDN = window.config.tinymceCDNUrl

let _refreshInterval = null

// Check for deeplink on initial load, electron only
// Overrides previously saved token from localStorage
// Mimics the "reloading" state to initialize with the new token
const deeplink = window.config && window.config.deeplink
if (deeplink && deeplink.token) {
	localStorage.setItem('token', deeplink.token)
	localStorage.setItem('expiration', 0)
	sessionStorage.setItem('session', 'session')
	delete deeplink.token
}

const state = {
	token: storage.getItem('token') || '',
	expiration: storage.getItem('expiration') || '',
	claims: getClaimsFromToken(storage.getItem('token')) || {},
}

storage.removeItem('tokenUseCount') // clean up old implementation data; TODO: remove this after a while

if (state.token) {
	let reloading = !!sessionStorage.getItem('session')
	let count = omniDesktop.isMainWindow ? 0 : storage.getTokenUseCount()

	if (count <= 0 && !reloading) {
		storage.removeItem('token')
		storage.removeItem('apiDns')
		storage.removeItem('annexDns')
		state.token = ''
	} else {
		storage.addTokenUse()
		const apiDns = storage.getItem('apiDns')
		const annexDns = storage.getItem('annexDns')
		if (apiDns && annexDns) api.setApiBaseUrl(apiDns, annexDns)
		api.auth.setHeader(state.token)
	}
}

function onAppClosing() {
	const { name: routeName, path: routePath } = router.currentRoute
	eventBus.broadcast(eventBus.type.CLOSE, { routeName, routePath })

	if (!state.token) {
		return
	}

	// decrement token use count
	storage.removeTokenUse()

	// clear workstation login, emit logout to popup windows
	if (storage.getTokenUseCount() <= 0 || omniDesktop.isMainWindow) {
		api.auth.logOut()
		eventBus.broadcast(eventBus.type.LOGOUT)
		// Close WebSockets
		wsData.closeConnection()
	}
}

const getters = {
	isAuthenticated: state => !!state.token,
	isConsultantUser: state => !!state.claims.isConsultantUser,
	isRepositoryUser: state => !!state.claims.isRepositoryUser,
	// TODO: Finish these claims
	isSaleSubmissionUser: state => !!(state.claims.is || state.claims.is || true),
	isCommunityUser: state => state.claims.isCommunityUser,
	username: state => state.claims.username,
	fullname: state => state.claims.fullname,
}

const mutations = {
	setAuthentication(state, { token, expiration }) {
		state.token = token
		state.expiration = expiration
		state.claims = getClaimsFromToken(token)
		storage.setItem('token', token)
		storage.setItem('expiration', expiration)
		api.auth.setHeader(token)
	},
	CLEAR_AUTHENTICATION(state) {
		if (!state.token) return

		storage.removeItem('token')
		storage.removeItem('apiDns')
		storage.removeItem('annexDns')
		storage.clearTokenUses()

		api.auth.logOut()
		api.auth.unsetHeader()

		state.token = ''
		state.expiration = ''
		state.claims = {}
	},
}

const actions = {
	clearAllData({ commit, dispatch }) {
		commit('CLEAR_AUTHENTICATION')
		commit('CLEAR_REPOSITORY_DATA')
		commit('CLEAR_STATICS')
		uploadData.stopUploads()
		commit('RESET_LIST_UI_CACHE')
		commit('SET_LAST_ACTIVE_SETTING', '')
		dispatch('UNSET_USER')
		dispatch('stopIdleTimer')
		commit('CLEAR_SERIES_VIEWPORT_CACHE')
		commit('SET_OPEN_STUDIES_NEW_WINDOW', false)
		commit('SET_LOAD_SERIES_FROM_MIDDLE', null)
		commit('SET_IS_USER_DATA_LOADED', false)
		clearTempStorage()
		salesService.reset()
		workflow.reset()
		lockService.stopAutoRefreshing()
		lockService.clear()
		studyFolders.clear()
		clinicAPI.stopScanning()
		eventBus.post('clear-all-data')
	},
	async refreshTokenAndBroadcast({ dispatch }, init) {
		dispatch('refreshToken', init)
		eventBus.broadcast(eventBus.type.VUEX_ACTION, { type: 'refreshToken', payload: init })
	},
	async refreshToken({ commit, dispatch, getters }, init) {
		if (getters.isAuthenticated) {
			let response = await api.auth.refreshToken()
			if (response.data.needsUpdate) {
				dispatch('showAppUpdateAvailable')
			}
			if (init) {
				dispatch('setAuthenticationAndInit', response)
			} else {
				const { expires_in: expiration, access_token: token, warning } = response.data
				if (warning) {
					dispatch('addNotification', {
						message: warning,
						notificationType: 'warn',
					})
				}
				commit('setAuthentication', { token, expiration })
			}
		}
	},
	async initAuth({ dispatch, state }) {
		sessionStorage.setItem('session', 'session')

		// Previously saved or from deeplink
		if (state.token) {
			await dispatch('refreshToken', true)
		}

		eventBus.on('logout', () => {
			if (state.token) {
				dispatch('logOut')
			}
		})
	},
	async setAuthenticationAndInit({ commit, dispatch, getters, state }, response) {
		const {
			apiDns,
			annexDns,
			clinicUrls,
			expires_in: expiration,
			access_token: token,
			googleAnalyticsId,
		} = response.data

		if (!expiration || !token)
			throw new Error(`Expected non-null expiration and token but received: exp: ${expiration} -- token: ${token}`)
		commit('setAuthentication', { token, expiration })

		// check for community or repository users, throw error message and don't login
		if (omniDesktop.isConnected && (state.claims.isCommunityUser || state.claims.isRepositoryUser)) {
			commit('CLEAR_AUTHENTICATION')
			const msg = `
			<p>The Keystone Omni desktop application is for Asteris customers only.</p>
			<p>If you are interested in learning more or becoming an Asteris customer, please contact us at <a href="mailto:sales@asteris.com">sales@asteris.com</a>.</p>
			`
			await showConfirm(msg, { title: 'Omni Desktop', confirmText: null, cancelText: 'OK' })
			return
		}

		clinicAPI.setClinicUrls(clinicUrls)

		dicomPartners.configs = response.data.partnerConfigs

		storage.setItem('token', state.token)
		storage.setItem('expiration', state.expiration)
		storage.setItem('lastLogin', getters.isCommunityUser ? 'community' : 'omni')
		// Will get used on next load :/
		storage.setItem('ga:tokenId', googleAnalyticsId)

		// Clear saved cache items on login
		sessionStorage.removeItem('temp:studies:filter')
		sessionStorage.removeItem('temp:teleconsultations:filter')

		storage.setItem('apiDns', apiDns)
		storage.setItem('annexDns', annexDns)
		api.setApiBaseUrl(apiDns, annexDns)

		omniDesktop.updateConfig(annexDns, state.claims.activeClinicCode, API.defaults.baseURL)
		api.auth.setHeader(token)

		if (state.claims.isConsultantUser) {
			lockService.startAutoRefreshing()
		}
		clinicAPI.startScanning()
		await dispatch('initStatics')
		if (getters.isRepositoryUser) dispatch('startIdleTimer')
		// Tracking: Analytics and Sentry
		dispatch('SET_USER', {
			id: state.claims.userId,
			clinicId: state.claims.activeClinicId,
			clinicCode: state.claims.activeClinicCode,
			username: state.claims.username,
			isCommunity: getters.isCommunityUser,
			isDesktop: omniDesktop.isConnected,
		})
		wsData.start()
		RefreshToken.initializeRefreshToken()
		RefreshToken.enableRefreshToken()
		eventBus.post('login')
	},
	async logIn({ commit, dispatch, getters }, { data }) {
		commit('clearErrorNotifications')
		const response = await api.auth.logIn(data)
		if (response.data.needsUpdate) {
			dispatch('showAppUpdateAvailable')
		}
		if (response.data.access_token) {
			storage.clearTokenUses()
			storage.addTokenUse()
		}
		await dispatch('setAuthenticationAndInit', response)
		try {
			GoogleAnalytics.sendGAEvent('LogIn', {
				event_category: 'User',
				event_label: 'LogIn',
			})
		} catch (err) {
			console.log(err)
		}
	},
	async addConsultant({ state, dispatch }, { consultantId }) {
		const userId = state.claims.userId
		if (!userId || !consultantId) return
		const response = await api.user.addConsultantToUser({ userId, consultantId })
		const consultantWasAdded = response && response.data === true
		if (consultantWasAdded) {
			await dispatch('getConsultants')
			dispatch('addNotification', {
				message: 'A new consultant has been added to your account!',
				notificationType: 'success',
			})
		}
	},
	async createCommunityUser({ dispatch }, { data }) {
		dispatch('clearAllData')
		let result = await api.user.getCommunityDns(data.consultantId)
		api.setApiBaseUrl(result.communityDns, result.annexDns)
		const response = await api.user.createCommunityUser(data)
		await dispatch('setAuthenticationAndInit', response)
		analyticsEvent('Global', 'CreateCommunityUser')
	},
	async resetPassword({ dispatch }, data) {
		const response = await api.auth.resetPassword(data)
		await dispatch('setAuthenticationAndInit', response)
	},
	async switchClinic({ dispatch, getters }, { id }) {
		router.replace('/switching-clinics')
		const response = await api.auth.switchClinic({ clinicId: id })
		await dispatch('logOut', { changeRoute: false, forceLogout: true })

		await dispatch('setAuthenticationAndInit', response)
		if (getters.isAuthenticated) {
			storage.clearTokenUses()
			storage.addTokenUse()
		}
		dispatch('applyTheme')
		router.replace('/')
	},
	async logOut({ dispatch }, { forceLogout = false, changeRoute = true } = {}) {
		if (!forceLogout && !(await checkSafeToLogout())) return
		dispatch('clearAllData')
		if (changeRoute) {
			router.push('/')
			dlg.closeAll()
		}

		eventBus.post(eventBus.type.LOGOUT)
		eventBus.broadcast(eventBus.type.LOGOUT)

		// Disable token auto-refreshing
		if (_refreshInterval) clearInterval(_refreshInterval)

		// Close WebSockets
		wsData.closeConnection()
		RefreshToken.stopRefreshToken()
	},
}

function getClaimsFromToken(token) {
	let claims = {}
	if (!token) return claims

	try {
		const decoded = jwtDecode(token)

		claims.userId = decoded['usid']
		claims.defaultClinicId = decoded['uspmclid']
		claims.defaultClinicCode = decoded['uspmclcd']
		claims.permissionMask = decoded['uspmmsk']
		claims.username = decoded['sub']
		claims.fullname = `${decoded['usfn']} ${decoded['usln']}`
		claims.partnerClinicCode = decoded['uspartner']

		claims.activeClinicId = decoded['actclid']
		claims.activeClinicCode = decoded['actclcd']
		claims.sessionId = decoded['actssid']

		claims.isRepositoryUser = decoded['isrpus']
		claims.isConsultantUser = decoded['iscsus']
		claims.isConsultantMember = decoded['iscsmb']
		claims.isConsultantGroup = decoded['iscsgp']
		claims.isConsultantIntern = decoded['iscsit']
		claims.isConsultantResident = decoded['iscsrs']
		claims.isConsultantTranscriptionist = decoded['iscstr']
		claims.isCommunityUser = decoded['iscmus']
		claims.isAdvancedReportsEnabled = decoded['isare']
		claims.isSchedulingEnabled = decoded['isse']

		// Indicates whether the org/group should see UI around resident/intern workflow
		claims.isConsultantApprovalSupported = decoded['iscapr']
	} catch (err) {}

	return claims
}

async function clearTempStorage() {
	try {
		let keys = Object.keys(localStorage)
		let tempKeys = keys.filter(key => key.startsWith('temp:'))
		tempKeys.forEach(key => storage.removeItem(key))
	} catch (err) {
		console.log(err)
	}
}

export default {
	state,
	getters,
	mutations,
	actions,
}
