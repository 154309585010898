const isSharedWorkerSupported = () => {
	if (typeof SharedWorker === 'undefined') return false
	let supports = false
	const tester = {
			get type() {
					supports = true
			},
	}
	try {
			const blob = new Blob([''], { type: 'application/javascript' })
			const url = URL.createObjectURL(blob)
			supports = !!new SharedWorker(url, tester)
			URL.revokeObjectURL(url)
			return supports
	} finally {
			// Cleanup if needed
	}
}

	const createMessageSender = (worker, isEnabled) => (msg) => {
		if (isEnabled) {
			try { worker.postMessage(msg)

			}
			catch (error) {
				console.error('Failed to send message to worker:', error)
			}
		}
	}

	export {
		isSharedWorkerSupported,
		createMessageSender
	}
