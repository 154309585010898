




























































import PDFJS, { getDocument, PDFDocumentProxy, PDFPageProxy, PDFRenderParams, PDFPageViewport } from 'pdfjs-dist'
import { eventBus } from '@services/eventBus'
import AstToolbar from '@components/Toolbar.vue'
import ToolbarButton from '@components/ToolbarButton.vue'
import { downloadFile } from '@services/api'

import {
	defineComponent,
	reactive,
	computed,
	watch,
	onMounted,
	onBeforeUnmount,
	SetupContext,
} from '@vue/composition-api'

export default defineComponent({
	name: 'PdfViewer',
	components: {
		AstToolbar,
		ToolbarButton,
	},
	props: {
		src: {
			required: true,
		},
		showDownloadButton: {
			type: Boolean,
			default: true,
		},
		showReviewButton: {
			type: Boolean,
			default: false,
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, context: SetupContext) {
		let pageRetryCount = 0
		let document: PDFDocumentProxy = null
		let state = reactive({
			isLoadingNewDocument: false,
			isLoadingPage: false,
			loadAgain: false,
			currentPage: 1,
			scale: 100,
			totalPages: 0,
		})

		let canvases: NodeListOf<HTMLCanvasElement>
		let pdfContent: HTMLElement
		let textLayers: NodeListOf<HTMLElement>

		async function openDocument() {
			if (document) {
				state.isLoadingNewDocument = true
			}
			try {
				document = await getDocument(props.src).promise
				state.totalPages = document.numPages
				context.root.$nextTick(async () => {
					pdfContent = context.root.$el.querySelector('.pdf-scroll')
					canvases = context.root.$el.querySelectorAll('canvas')
					textLayers = context.root.$el.querySelectorAll('.textLayer')
					await loadPages()
					state.isLoadingNewDocument = false
				})
			} catch (err) {
				console.error(err)
				context.root.$store.dispatch('addNotification', {
					message: 'The PDF failed to load.',
					notificationType: 'error',
				})
				context.emit('close')
			}
		}

		async function setPage(pageNum: number) {
			if (pageNum < 1) pageNum = 1
			if (pageNum > state.totalPages) pageNum = state.totalPages
			state.currentPage = pageNum
			canvases[pageNum - 1].scrollIntoView()
		}

		function setScale(s: number) {
			state.scale = s
			loadPages()
		}

		async function loadPages() {
			let i = 1
			while (i <= state.totalPages) {
				await loadPage(i)
				i++
			}
		}

		async function loadPage(pageNumber) {
			if (!document) {
				if (pageRetryCount < 3) {
					pageRetryCount++
					setTimeout(loadPage, 500)
				}
				return
			}

			if (state.isLoadingPage) {
				state.loadAgain = true
				return
			}
			state.isLoadingPage = true
			let page = await document.getPage(pageNumber)
			const viewerWidth = pdfContent.getBoundingClientRect().width - 32
			const scaleFactor = viewerWidth / page.view[2]
			const finalscale = (state.scale / 100) * scaleFactor
			const viewport = page.getViewport({ scale: finalscale })
			const canvas = canvases[pageNumber - 1]
			const canvasContext = canvas.getContext('2d')
			canvas.style.width = viewport.width + 'px'
			canvas.style.height = viewport.height + 'px'
			canvas.width = viewport.width
			canvas.height = viewport.height
			const renderContext: PDFRenderParams = {
				canvasContext,
				viewport,
			}
			await page.render(renderContext).promise
			await updateTextLayer(page, pageNumber, viewport, canvas)
			if (state.loadAgain) {
				await loadPage(pageNumber)
				state.loadAgain = false
			}
			state.isLoadingPage = false
		}

		async function updateTextLayer(
			page: PDFPageProxy,
			pageNumber: number,
			viewport: PDFPageViewport,
			canvas: HTMLCanvasElement
		) {
			const textLayer = textLayers[pageNumber - 1]
			textLayer.innerHTML = ''
			const canvasRect = canvas.getBoundingClientRect()
			textLayer.style.width = canvasRect.width + 'px'
			textLayer.style.height = canvasRect.height + 'px'
			const textContent = await page.getTextContent()

			// @ts-ignore
			await PDFJS.renderTextLayer({
				textContent,
				container: textLayer,
				viewport,
				textDivs: [],
			}).promise
			// toggle text layer to fix scrollbar
			textLayer.style.display = 'none'
			setTimeout(() => {
				textLayer.style.display = 'block'
			}, 0)
		}

		function zoom(delta = 0) {
			if (state.scale === 25 && delta < 0) return // do not go below 25%
			if (state.scale === 500 && delta > 0) return // do not go above 500%
			setScale(state.scale + delta)
			setTimeout(onScroll, 50) // update currentPage after re-rendering
		}

		const loaded = computed(() => {
			return state.totalPages > 0
		})

		const isNextPage = computed(() => {
			return state.currentPage !== state.totalPages
		})

		const isPrevPage = computed(() => {
			return state.currentPage !== 1
		})

		const onScroll = () => {
			let page = 1
			for (let i = 1; i < state.totalPages; i++) {
				if (pdfContent.scrollTop < canvases[i].parentElement.offsetTop - pdfContent.clientHeight / 2) break
				page++
			}
			state.currentPage = page
		}

		watch(() => props.src, openDocument)

		onMounted(() => {
			eventBus.on('resize', zoom)
		})

		onBeforeUnmount(() => {
			eventBus.off('resize', zoom)
		})

		return {
			loaded,
			isNextPage,
			isPrevPage,
			onScroll,
			scale: computed(() => state.scale),
			currentPage: computed(() => state.currentPage),
			isLoadingNewDocument: computed(() => state.isLoadingNewDocument),
			totalPages: computed(() => state.totalPages),
			markReviewed() {
				context.emit('close', { reviewed: true })
			},
			pagePrevious() {
				if (isPrevPage) setPage(state.currentPage - 1)
			},
			pageNext() {
				if (isNextPage) setPage(state.currentPage + 1)
			},
			zoomIn() {
				zoom(25)
			},
			zoomOut() {
				zoom(-25)
			},
			download() {
				downloadFile(props.src + '&Download=True')
			},
		}
	},
})
