import store from '@store'
import { isSharedWorkerSupported, createMessageSender } from '@utils/sharedWorkers'

const REFRESH_TOKEN_TIMER = 1000 * 60 * 5 // 5 minutes

let w
let isWorkerEnabled = false
const initializeRefreshToken = () => {
	isWorkerEnabled = isSharedWorkerSupported()
	if (!isWorkerEnabled) return
	w = new SharedWorker('refreshTokenWebWorker.js', 'asteris-refresh-token-worker').port
	w.start()
	w.onmessage = function(event) {
		if (event.data === 'refeshToken') {
			console.log('Refreshing token')
			try {
				store.dispatch('refreshToken')
			} catch (e) {
				console.error(e)
			}
		}
	}
}

function enableRefreshToken() {
	const msg = {
		type: 'enableRefreshToken',
		timer: parseInt(REFRESH_TOKEN_TIMER),
	}
	createMessageSender(w, isWorkerEnabled)(msg)
}

function disableRefreshToken() {
	const msg = {
		type: 'disableRefreshToken',
	}
	createMessageSender(w, isWorkerEnabled)(msg)
}

const stopRefreshToken = () => {
	const msg = {
		type: 'stopRefreshToken',
	}
	createMessageSender(w, isWorkerEnabled)(msg)
	if (isWorkerEnabled) {
		w.close()
	}
}

export { disableRefreshToken, enableRefreshToken, initializeRefreshToken, stopRefreshToken }
