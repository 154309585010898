// Refer to the flowchart in the project Documentation folder
// ** Avoid tying Vue reactivity to any of this as it slows down image loading! **

import '@/cornerstone/increaseCornerstoneCache'
import omniDesktop from '@/electron/omniDesktop'
import shouldLoadDicom from '@/utils/shouldLoadDicom'
import store from '@store'
import { findImageUrl } from '@utils/urlUtility'
import axios, { Canceler } from 'axios'
import {
	events as csEvents,
	metaData as csMetaData,
	imageCache,
	loadAndCacheImage,
	registerImageLoader,
} from 'cornerstone-core/dist/cornerstone'
import { getToolState } from 'cornerstone-tools/dist/cornerstoneTools.js'

import { dicomServiceData } from '@services/dicomServiceData'

// @ts-ignore
import QueuePrioritizeWorker from 'worker-loader!./QueuePrioritizeWorker' // eslint-disable-line

import { dicomDecoder } from './dicomDecoder'
import localImageCache from './imageCache'
import decodeJpeg, { jpegCanvas } from './jpegDecoder'

const MAX_FETCHES = 6
const MIN_IMAGE_BYTE_LENGTH = 1000
const PREFETCH_DEBOUNCER = 50
const IMAGE_DOWNLOAD_DEBOUNCER = 100

const FetchAxios = axios.create({
	responseType: 'arraybuffer',
	transformRequest: [
		(data, headers) => {
			delete headers.common['Authorization']
			return data
		},
	],
})

let prefetchDebounce

type QueueItem = {
	cornerstoneImageId: string
	loadImagePromise: Promise<any>
	isPrefetch: boolean
	isUnopenedSeries: boolean
}

let queue: QueueItem[] = []

class ImageLoader {
	loadImageDeferreds: Map<string, DeferredPromise> = new Map()
	activeFetches = 0
	fetchCancelers: Map<string, Canceler> = new Map()

	constructor() {
		this.prefetchAsync = this.prefetchAsync.bind(this)
		this.processTempQueue = this.processTempQueue.bind(this)
	}

	// this method is registered with cornerstone for image loading
	loadImage(cornerstoneImageId: string, { isPrefetch = false, isUnopenedSeries = false } = {}) {
		const add = isPrefetch ? 'push' : 'unshift'
		const loadImageDeferred: DeferredPromise = {}
		const loadImagePromise = new Promise((resolve, reject) => {
			loadImageDeferred.resolve = resolve
			loadImageDeferred.reject = reject
		})
		const queueItem: QueueItem = {
			cornerstoneImageId,
			loadImagePromise,
			isPrefetch,
			isUnopenedSeries,
		}
		queue[add](queueItem)
		this.loadImageDeferreds.set(cornerstoneImageId, loadImageDeferred)
		this.startFetching()
		return {
			promise: loadImagePromise,
			cancelFn: undefined,
		}
	}

	async processTempQueue(tempQueue) {
		while (tempQueue.length) {
			const queueItem = tempQueue.shift()
			const { cornerstoneImageId } = queueItem
			this.fetchAndDecodeImage(cornerstoneImageId)
		}
	}
	startFetching() {
		while (queue.length && this.activeFetches < MAX_FETCHES) {
			const isUnopenedSeries = queue[0].isUnopenedSeries
			const isCacheFull = isCacheFullOfOpenStudies()
			if (isUnopenedSeries && isCacheFull) {
				// do not prefetch any more unopened series if cache is full
				this.clearQueue()
				break
			}
			let tempQueue = []
			for (let i = 0; i < MAX_FETCHES; i++) {
				if (!queue.length) break
				this.activeFetches++
				const queueItem = queue.shift()
				tempQueue.push(queueItem)
			}
			setTimeout(this.processTempQueue, IMAGE_DOWNLOAD_DEBOUNCER, tempQueue)
		}
	}

	async prefetchAsync(scrolledToIndex?: number) {
		const { maximumSizeInBytes, cacheSizeInBytes } = imageCache.getCacheInfo()
		if (cacheSizeInBytes > maximumSizeInBytes) return
		const loadedCornerstoneImageIds = getLoadedCornerstoneImageIds()
		// do not rebuild queue when scrolling if the open series are already loaded
		if (scrolledToIndex != null && areOpenSeriesFullyLoaded(loadedCornerstoneImageIds)) return
		const newQueueItems = await getPrefetchQueue(loadedCornerstoneImageIds, scrolledToIndex)
		queue = []
		let prefetchFetchCount = 0
		let highPriorityCount = 0
		// rebuild queue
		let i = 0
		while (i < newQueueItems.length) {
			const { cornerstoneImageId, isPrefetch, isUnopenedSeries } = newQueueItems[i]
			i++
			// get any existing promises from the cornerstone imagecache
			let pendingLoad = imageCache.getImageLoadObject(cornerstoneImageId)
			if (pendingLoad && !this.loadImageDeferreds.has(cornerstoneImageId)) {
				// if we do not have a deferred for the pendingLoad.promise, remove the pending load
				imageCache.removeImageLoadObject(cornerstoneImageId)
				pendingLoad = null
			}
			if (pendingLoad && !isPrefetch) {
				// move non-prefetch queue item up
				queue.push({
					cornerstoneImageId,
					loadImagePromise: pendingLoad.promise,
					isPrefetch: false,
					isUnopenedSeries: false,
				})
				highPriorityCount++
			} else if (pendingLoad) {
				const isFetching = this.fetchCancelers.has(cornerstoneImageId)
				if (isFetching) {
					prefetchFetchCount++
					// cancel the lowest-priority active fetches as necessary to free up connections for new
					// high-priority (visible) images
					if (prefetchFetchCount > MAX_FETCHES - highPriorityCount) this.stopFetch(cornerstoneImageId)
					else continue // do not cancel any other active fetches
				}
				if (dicomDecoder.isDecoding(cornerstoneImageId)) continue // do not cancel active decoding tasks
				// cancel and restart prefetches
				imageCache.removeImageLoadObject(cornerstoneImageId)
				dicomDecoder.stop(cornerstoneImageId)
				this.loadImageDeferreds.delete(cornerstoneImageId)
				loadAndCacheImage(cornerstoneImageId, { isPrefetch, isUnopenedSeries })
			} else {
				loadAndCacheImage(cornerstoneImageId, { isPrefetch, isUnopenedSeries })
			}
		}
	}

	prefetch(scrolledToIndex?: number) {
		clearTimeout(prefetchDebounce)
		prefetchDebounce = setTimeout(this.prefetchAsync, PREFETCH_DEBOUNCER, scrolledToIndex)
	}

	stop() {
		this.loadImageDeferreds.forEach((d, k) => {
			imageCache.removeImageLoadObject(k)
		})
		this.loadImageDeferreds.clear()
		this.stopFetch()
		queue = []
		dicomDecoder.stop()
	}

	clearQueue() {
		queue.forEach(({ cornerstoneImageId }) => {
			if (imageCache.cachedImages.some(i => i.imageId === cornerstoneImageId))
				imageCache.removeImageLoadObject(cornerstoneImageId)
			this.loadImageDeferreds.delete(cornerstoneImageId)
		})
		queue = []
	}

	stopFetch(cornerstoneImageId?: string) {
		if (cornerstoneImageId && this.fetchCancelers.has(cornerstoneImageId)) {
			this.fetchCancelers.get(cornerstoneImageId)()
			this.fetchCancelers.delete(cornerstoneImageId)
		} else if (!cornerstoneImageId) {
			this.fetchCancelers.forEach(canceler => canceler())
			this.fetchCancelers.clear()
		}
	}

	async fetchAndDecodeImage(cornerstoneImageId: string): Promise<any> {
		try {
			const arrayBuffer = await this.fetchImage(cornerstoneImageId)
			if (!arrayBuffer) return
			const decoded = await this.decodeImage(cornerstoneImageId, arrayBuffer)
			if (!decoded) return
			const cornerstoneImage = this.createCornerstoneImage(cornerstoneImageId, decoded)
			if (!cornerstoneImage) return
			this.loadImageDeferreds.get(cornerstoneImageId).resolve(cornerstoneImage)
			this.loadImageDeferreds.delete(cornerstoneImageId)
		} catch (err) {
			if (this.loadImageDeferreds.has(cornerstoneImageId)) {
				this.loadImageDeferreds.get(cornerstoneImageId).reject(err)
				this.loadImageDeferreds.delete(cornerstoneImageId)
			}
			if (err && err.message) {
				store.dispatch('addNotification', {
					message: err.message,
					notificationType: 'error',
				})
			}
		}
	}

	async fetchImage(cornerstoneImageId: string): Promise<ArrayBuffer> {
		try {
			let data = getCornerstoneImageData(cornerstoneImageId)
			if (dicomServiceData.localStudies.has(data.studyUid)) {
				try {
					return await dicomServiceData.downloadLocalImage(data.instanceUid, data.scheme)
				} catch {}
			}

			const fetchImageFromLocalServerOrAnnex = async () => {
				// Build image urls for connected server and Annex (won't be the same if connected to local server)
				const imageUrl = findImageUrlByCornerstoneImageId(cornerstoneImageId)
				const annexImageUrl = findImageUrlByCornerstoneImageId(cornerstoneImageId, true)
				// Fetch the image from the local server or Annex
				let image = await this.fetchImageUrl(cornerstoneImageId, imageUrl)
				// If invalid image was fetched from local server, fall back to Annex
				if (!validateImageArrayBuffer(image) && imageUrl !== annexImageUrl) {
					image = await this.fetchImageUrl(cornerstoneImageId, annexImageUrl)
				}
				return image
			}

			// For now, we just use the cache for Omni Desktop
			if (omniDesktop.isConnected) {
				return await localImageCache.loadImage(data, fetchImageFromLocalServerOrAnnex)
			} else {
				return await fetchImageFromLocalServerOrAnnex()
			}
		} finally {
			this.activeFetches--
			this.startFetching()
		}
	}

	async fetchImageUrl(cornerstoneImageId: string, url: string, retryCount = 0): Promise<ArrayBuffer> {
		try {
			const arrayBuffer = await FetchAxios.get(url, {
				cancelToken: new axios.CancelToken(cancel => {
					this.fetchCancelers.set(cornerstoneImageId, cancel)
				}),
			}).then(r => {
				return r.data
			})
			return arrayBuffer
		} catch (err) {
			if (retryCount < 4) {
				return this.fetchImageUrl(cornerstoneImageId, url, ++retryCount)
			} else {
				throw new Error('The requested image failed to download.')
			}
		} finally {
			this.fetchCancelers.delete(cornerstoneImageId)
		}
	}

	async decodeImage(cornerstoneImageId: string, arrayBuffer: ArrayBuffer): Promise<any> {
		if (!this.loadImageDeferreds.has(cornerstoneImageId)) return
		const startTime = Date.now()
		let decoded
		const { scheme } = getCornerstoneImageIdParts(cornerstoneImageId)
		if (scheme === 'dicom') {
			decoded = await dicomDecoder.decodeDicom(cornerstoneImageId, arrayBuffer)
		} else {
			decoded = await decodeJpeg(arrayBuffer)
		}
		if (decoded) decoded.decodeTimeInMS = Date.now() - startTime
		return decoded
	}

	createCornerstoneImage(cornerstoneImageId: string, decoded) {
		if (!this.loadImageDeferreds.has(cornerstoneImageId)) return
		const { imageId: asterisImageId, frameIndex } = getCornerstoneImageIdParts(cornerstoneImageId)
		const imagePixelModule = csMetaData.get('imagePixelModule', asterisImageId) || {}
		const imagePlaneModule = csMetaData.get('imagePlaneModule', asterisImageId) || {}
		const voiLutModule = csMetaData.get('voiLutModule', asterisImageId) || {}
		const modalityLutModule = csMetaData.get('modalityLutModule', asterisImageId) || {}
		const sopCommonModule = csMetaData.get('sopCommonModule', asterisImageId) || {}
		const photometricInterpretation = decoded.photometricInterpretation || imagePixelModule.photometricInterpretation
		// https://groups.google.com/forum/#!searchin/comp.protocols.dicom/Modality$20LUT$20XA/comp.protocols.dicom/UBxhOZ2anJ0/D0R_QP8V2wIJ
		const isModalityLutForDisplay =
			sopCommonModule.sopClassUID !== '1.2.840.10008.5.1.4.1.1.12.1' &&
			sopCommonModule.sopClassUID !== '1.2.840.10008.5.1.4.1.1.12.2.1'
		const image = {
			getCanvas: () => jpegCanvas,
			getImage: decoded.imageElement ? () => decoded.imageElement : undefined,
			getPixelData: decoded.pixelData ? () => decoded.pixelData : undefined,
			imageId: cornerstoneImageId,
			asterisImageId,
			frameIndex,
			render: decoded.render,
			rgba: decoded.rgba || false,
			rows: decoded.rows || imagePixelModule.rows,
			height: decoded.rows || imagePixelModule.rows,
			columns: decoded.columns || imagePixelModule.columns,
			width: decoded.columns || imagePixelModule.columns,
			color: decoded.isColor,
			minPixelValue:
				decoded.minPixelValue != null
					? decoded.minPixelValue
					: imagePixelModule.smallestPixelValue != null
					? imagePixelModule.smallestPixelValue
					: 0,
			maxPixelValue:
				decoded.maxPixelValue != null
					? decoded.maxPixelValue
					: imagePixelModule.largestPixelValue != null
					? imagePixelModule.largestPixelValue
					: 255,
			rowPixelSpacing: imagePlaneModule.rowPixelSpacing,
			columnPixelSpacing: imagePlaneModule.columnPixelSpacing,
			slope:
				decoded.slope != null
					? decoded.slope
					: modalityLutModule.rescaleSlope != null
					? modalityLutModule.rescaleSlope
					: 1,
			intercept:
				decoded.intercept != null
					? decoded.intercept
					: modalityLutModule.rescaleIntercept != null
					? modalityLutModule.rescaleIntercept
					: 0,
			invert: decoded.invert != null ? decoded.invert : photometricInterpretation === 'MONOCHROME1',
			dicomWindowCenter:
				voiLutModule.windowCenter && voiLutModule.windowCenter[0] != null ? voiLutModule.windowCenter[0] : 128,
			windowCenter:
				decoded.windowCenter != null ? decoded.windowCenter : voiLutModule.windowCenter && voiLutModule.windowCenter[0],
			dicomWindowWidth:
				voiLutModule.windowWidth && voiLutModule.windowWidth[0] != null ? voiLutModule.windowWidth[0] : 255,
			windowWidth:
				decoded.windowWidth != null ? decoded.windowWidth : voiLutModule.windowWidth && voiLutModule.windowWidth[0],
			voiLUT: voiLutModule.voiLUTSequence && voiLutModule.voiLUTSequence[0],
			modalityLut:
				(isModalityLutForDisplay &&
					modalityLutModule.modalityLUTSequence &&
					modalityLutModule.modalityLUTSequence[0]) ||
				undefined,
			sizeInBytes: decoded.pixelData.buffer.byteLength,
			decodeTimeInMS: decoded.decodeTimeInMS,
			isDicom: decoded.isDicom || false,
			decodeValues: {
				// used to recalculate image values if metadata is loaded later
				minPixelValue: decoded.minPixelValue,
				maxPixelValue: decoded.maxPixelValue,
				slope: decoded.slope,
				intercept: decoded.intercept,
				invert: decoded.invert,
				windowCenter: decoded.windowCenter,
				windowWidth: decoded.windowWidth,
			},
		}

		if (image.windowCenter == null || image.windowWidth == null) {
			const maxVoi = image.maxPixelValue * image.slope + image.intercept
			const minVoi = image.minPixelValue * image.slope + image.intercept
			image.windowWidth = maxVoi - minVoi
			image.windowCenter = (maxVoi + minVoi) / 2
			image.dicomWindowCenter = image.windowCenter
			image.dicomWindowWidth = image.windowWidth
		}

		return image
	}
}

export function validateImageArrayBuffer(buffer: ArrayBuffer): Boolean {
	return buffer && buffer.byteLength > MIN_IMAGE_BYTE_LENGTH
}

let studiesOpenWhenLastDecached

export function decacheInactiveStudies() {
	// do not attempt to decache again if the studies haven't changed since last attempt
	if (isCacheFullOfOpenStudies(true)) return

	const allImages = [].concat(...store.getters.allSeries.map(series => series.images))
	let decached = 0
	for (let i = imageCache.cachedImages.length - 1; i >= 0; i--) {
		if (!imageCache.cachedImages[i].image) continue
		const { asterisImageId, imageId: cornerstoneImageId, frameIndex } = imageCache.cachedImages[i].image
		if (allImages.some(image => image.imageId === asterisImageId)) continue
		imageProgress.delete(asterisImageId + frameIndex)
		imageCache.removeImageLoadObject(cornerstoneImageId)
		decached++
	}
	return decached
}

export function isCacheFullOfOpenStudies(triggeredByCacheFullEvent = false) {
	const studiesOpen = store.state.viewer.studies
		.map(s => s.studyId)
		.sort()
		.join('')
	if (studiesOpenWhenLastDecached === studiesOpen) return true
	if (triggeredByCacheFullEvent) studiesOpenWhenLastDecached = studiesOpen
	return false
}

export function updatePrecachedCornerstoneImage(asterisImageId: string) {
	// if an unopened series was prefetched, we need to regenerate the cornerstone image values once the
	// actual metadata JSON is loaded
	const cachedImageObjects = imageCache.cachedImages.filter(i => i.image && i.image.asterisImageId === asterisImageId)
	if (!cachedImageObjects.length) return
	let i = 0
	while (i < cachedImageObjects.length) {
		const { image } = cachedImageObjects[i]
		const imagePixelModule = csMetaData.get('imagePixelModule', image.asterisImageId) || {}
		const imagePlaneModule = csMetaData.get('imagePlaneModule', image.asterisImageId) || {}
		const voiLutModule = csMetaData.get('voiLutModule', image.asterisImageId) || {}
		const modalityLutModule = csMetaData.get('modalityLutModule', image.asterisImageId) || {}
		const sopCommonModule = csMetaData.get('sopCommonModule', image.asterisImageId) || {}
		const photometricInterpretation = image.photometricInterpretation || imagePixelModule.photometricInterpretation
		// https://groups.google.com/forum/#!searchin/comp.protocols.dicom/Modality$20LUT$20XA/comp.protocols.dicom/UBxhOZ2anJ0/D0R_QP8V2wIJ
		const isModalityLutForDisplay =
			sopCommonModule.sopClassUID !== '1.2.840.10008.5.1.4.1.1.12.1' &&
			sopCommonModule.sopClassUID !== '1.2.840.10008.5.1.4.1.1.12.2.1'

		image.rows = image.rows || imagePixelModule.rows
		image.height = image.rows || imagePixelModule.rows
		image.columns = image.columns || imagePixelModule.columns
		image.width = image.columns || imagePixelModule.columns
		image.minPixelValue =
			image.decodeValues.minPixelValue != null
				? image.decodeValues.minPixelValue
				: imagePixelModule.smallestPixelValue != null
				? imagePixelModule.smallestPixelValue
				: 0
		image.maxPixelValue =
			image.decodeValues.maxPixelValue != null
				? image.decodeValues.maxPixelValue
				: imagePixelModule.largestPixelValue != null
				? imagePixelModule.largestPixelValue
				: 255
		image.rowPixelSpacing = imagePlaneModule.rowPixelSpacing
		image.columnPixelSpacing = imagePlaneModule.columnPixelSpacing
		image.slope =
			image.decodeValues.slope != null
				? image.decodeValues.slope
				: modalityLutModule.rescaleSlope != null
				? modalityLutModule.rescaleSlope
				: 1
		image.intercept =
			image.decodeValues.intercept != null
				? image.decodeValues.intercept
				: modalityLutModule.rescaleIntercept != null
				? modalityLutModule.rescaleIntercept
				: 0
		image.invert =
			image.decodeValues.invert != null ? image.decodeValues.invert : photometricInterpretation === 'MONOCHROME1'
		image.dicomWindowCenter =
			voiLutModule.windowCenter && voiLutModule.windowCenter[0] != null ? voiLutModule.windowCenter[0] : 128
		image.windowCenter =
			image.decodeValues.windowCenter != null
				? image.decodeValues.windowCenter
				: voiLutModule.windowCenter && voiLutModule.windowCenter[0]
		image.dicomWindowWidth =
			voiLutModule.windowWidth && voiLutModule.windowWidth[0] != null ? voiLutModule.windowWidth[0] : 255
		image.windowWidth =
			image.decodeValues.windowWidth != null
				? image.decodeValues.windowWidth
				: voiLutModule.windowWidth && voiLutModule.windowWidth[0]
		image.voiLUT = voiLutModule.voiLUTSequence && voiLutModule.voiLUTSequence[0]
		image.modalityLut =
			(isModalityLutForDisplay && modalityLutModule.modalityLUTSequence && modalityLutModule.modalityLUTSequence[0]) ||
			undefined

		if (image.windowCenter == null || image.windowWidth == null) {
			const maxVoi = image.maxPixelValue * image.slope + image.intercept
			const minVoi = image.minPixelValue * image.slope + image.intercept
			image.windowWidth = maxVoi - minVoi
			image.windowCenter = (maxVoi + minVoi) / 2
			image.dicomWindowCenter = image.windowCenter
			image.dicomWindowWidth = image.windowWidth
		}
		i++
	}
}

function areOpenSeriesFullyLoaded(loadedCornerstoneImageIds: Set<string>) {
	const { visibleCanvases, allSeries } = store.getters
	const openSeries = []
	let i = 0
	while (i < allSeries.length) {
		const series = allSeries[i]
		if (visibleCanvases.some(c => c.seriesId === series.seriesId)) openSeries.push(series)
		i++
	}
	i = 0
	while (i < openSeries.length) {
		const imageIds = getCornerstoneImageIdsForSeries(openSeries[i], loadedCornerstoneImageIds)
		i++
		if (imageIds.some(id => id !== null)) return false
	}
	return true
}

let queuePrioritizeWorker = new QueuePrioritizeWorker()

function getPrefetchQueue(loadedCornerstoneImageIds: Set<string>, scrolledToIndex?: number): Promise<any> {
	return new Promise(resolve => {
		const { visibleCanvases, allSeries } = store.getters
		const { activeCanvasIndex } = store.state.viewer
		const canvases = [visibleCanvases[activeCanvasIndex], ...visibleCanvases.filter((c, i) => i !== activeCanvasIndex)]
		const canvasSeriesIds = canvases.map(c => c.seriesId)
		const activeImageIndexes = canvases.map((c, i) => getActiveIndex(c, allSeries, i === 0, scrolledToIndex))
		const seriesImageIds: Map<string, string[]> = new Map()
		let i = 0
		while (i < allSeries.length) {
			const series = allSeries[i]
			const imageIds = getCornerstoneImageIdsForSeries(series, loadedCornerstoneImageIds)
			seriesImageIds.set(series.seriesId, imageIds)
			i++
		}
		queuePrioritizeWorker.onmessage = ({ data }) => resolve(data)
		queuePrioritizeWorker.postMessage({
			activeImageIndexes,
			canvasSeriesIds,
			seriesImageIds,
		})
	})
}

function getLoadedCornerstoneImageIds() {
	const imageIds: Set<string> = new Set()
	let i = 0
	while (i < imageCache.cachedImages.length) {
		const cachedImage = imageCache.cachedImages[i]
		if (cachedImage.image) imageIds.add(cachedImage.imageId)
		i++
	}
	return imageIds
}

function getActiveIndex(canvas, allSeries, isActiveCanvas, scrolledToIndex) {
	if (isActiveCanvas && scrolledToIndex >= 0) return scrolledToIndex
	// if canvas is visible, get active index from stack tool state
	let stack
	if (canvas.dom && canvas.dom.offsetParent) stack = getToolState(canvas.dom, 'stack')
	if (stack && stack.data && stack.data.length) {
		return stack.data[0].currentImageIdIndex
	}
	const series = allSeries.find(s => s.seriesId === canvas.seriesId)
	if (!series) return null
	return series.images.findIndex(i => i.imageId === canvas.asterisImageId)
}

export function getCornerstoneImageId(series, image) {
	const isDicom = shouldLoadDicom(series.modality)
	const scheme = isDicom ? 'dicom' : 'jpeg'
	const { clinicCode, endpointBaseUrl } = image.storageLocation || series.storageLocation || {}
	const cornerstoneImageId = `${scheme}:${image.imageId}:${image.frameIndex}:${clinicCode}:${endpointBaseUrl}`
	return cornerstoneImageId
}

export function getCornerstoneImageIdsForSeries(series, loadedCornerstoneImageIds): string[] {
	const cornerstoneImageIds = []
	let i = 0
	while (i < series.images.length) {
		const image = series.images[i]
		const cornerstoneImageId = getCornerstoneImageId(series, image)
		if (loadedCornerstoneImageIds && loadedCornerstoneImageIds.has(cornerstoneImageId)) cornerstoneImageIds.push(null)
		else cornerstoneImageIds.push(cornerstoneImageId)
		i++
	}
	return cornerstoneImageIds
}

function findImageUrlByCornerstoneImageId(cornerstoneImageId, annexOnly = false) {
	const { scheme, imageId, frameIndex, clinicCode, endpointBaseUrl } = getCornerstoneImageIdParts(cornerstoneImageId)
	return findImageUrl(
		{
			imageId,
			imageType: scheme === 'dicom' ? 'DicomImage' : 'PreviewImage',
			frameIndex,
			storageLocation: { clinicCode, endpointBaseUrl },
		},
		annexOnly
	)
}

export function getCornerstoneImageIdParts(cornerstoneImageId) {
	const [scheme, imageId, frameIndex, clinicCode, endpointBaseUrl] = cornerstoneImageId.split(':')

	return {
		scheme,
		imageId,
		frameIndex: parseInt(frameIndex) || 0,
		clinicCode,
		endpointBaseUrl,
	}
}

export function getCornerstoneImageData(cornerstoneImageId: string) {
	const { studies } = store.state.viewer
	const { scheme, imageId, frameIndex } = getCornerstoneImageIdParts(cornerstoneImageId)
	let result = null
	studies.some((study: any) =>
		study.imageData.series.some((series: any) => {
			series.images.some((image: any) => {
				if (image.imageId === imageId && image.frameIndex === frameIndex) {
					result = {
						clinicCode: study.clinicCode,
						studyId: series.studyId,
						studyUid: study.studyUid,
						seriesId: series.seriesId,
						seriesUid: series.uid,
						scheme,
						imageId,
						instanceUid: image.sopInstanceUid,
						frameIndex: image.frameIndex,
					}
					return true
				}
			})
		})
	)
	return result
}

// Map for thumbnail progress bars
export const imageProgress: Map<string, number> = new Map()
const onCornerstoneImageLoad = e => {
	const loadedImageId = e && e.detail.image.asterisImageId + e.detail.image.frameIndex
	imageProgress.set(loadedImageId, 1)
}
csEvents.addEventListener('cornerstoneimageloaded', onCornerstoneImageLoad)

export const imageLoader = new ImageLoader()
registerImageLoader('jpeg', imageLoader.loadImage.bind(imageLoader))
registerImageLoader('dicom', imageLoader.loadImage.bind(imageLoader))

window.imageLoader = imageLoader
