import store from '@store'
import windows from '@store/modules/windows'
import { isSharedWorkerSupported,createMessageSender } from '@/utils/sharedWorkers'

let w
let isWorkerEnabled = false
const initializeInactivityTimeout = () => {
	if (!window.config.enableInactivityTimeout) return
	console.log('Inactivity Timeout feature enabled')
	isWorkerEnabled = isSharedWorkerSupported()
	if (!window.config.enableInactivityTimeout || !isWorkerEnabled) return
	w = new SharedWorker('timerWebWorker.js', 'asteris-inactivity-logout-worker').port
	w.start()
	w.onmessage = function(event) {
		if (event.data === 'logout') {
			stopInactivityTimeout()
			window.localStorage.setItem('inactivityTimeout', true)
			// force to close all windows before logging out
			store.commit('CLOSE_ALL_STUDY_WINDOWS', windows.state.openedStudyWindowReferences)
			store.dispatch('logOut')
		}
	}

	window.onblur = resetTimer
	window.onfocus = resetTimer
	window.onclick = resetTimer
	document.addEventListener('mousemove', resetTimer, false)
	document.addEventListener('mousedown', resetTimer, false)
	document.addEventListener('keypress', resetTimer, false)
	document.addEventListener('touchmove', resetTimer, false)
	document.addEventListener('onscroll', resetTimer, false)
	startTimer()
}

function startTimer() {
	const msg = {
		type: 'enableTimeout',
		timer: parseInt(window.config.inactivityTimeout),
	}
	createMessageSender(w, isWorkerEnabled)(msg)
}

function resetTimer() {
	const msg = {
		type: 'resetTimeout',
		timer: parseInt(window.config.inactivityTimeout),
	}
	createMessageSender(w, isWorkerEnabled)(msg)
	startTimer()
}
const stopInactivityTimeout = () => {
	if (!window.config.enableInactivityTimeout || !isWorkerEnabled) return
	const msg = {
		type: 'disableTimeout',
		timer: parseInt(window.config.inactivityTimeout),
	}
	createMessageSender(w, isWorkerEnabled)(msg)
	window.onblur = null
	window.onfocus = null
	window.onclick = null
	document.removeEventListener('mousemove', resetTimer, false)
	document.removeEventListener('mousedown', resetTimer, false)
	document.removeEventListener('keypress', resetTimer, false)
	document.removeEventListener('touchmove', resetTimer, false)
	document.removeEventListener('onscroll', resetTimer, false)
	w.close()
}

export { initializeInactivityTimeout, stopInactivityTimeout }
